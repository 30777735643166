<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">เพิ่มแบนเนอร์</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ชื่อหัวข้อ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="title"
              label="ชื่อหัวข้อ"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row> -->
        <!-- <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">รายละเอียดแบนเนอร์</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-textarea
              v-model="categoryDescription"
              solo
              dense
              :rules="rules.required"
            ></v-textarea>
          </v-col>
        </v-row> -->
        <v-row align="center" justify="center">
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="10"
                  md="10"
                  sm="10"
                  xs="10"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูปแบนเนอร์</span>
                  <span style="color: red">*</span>
                  <v-img :src="showImage" width="200px"></v-img>
                  <v-text-field
                    v-model="picture_1"
                    @click="changePic1()"
                    :placeholder="imageName1"
                    prepend-icon="mdi-camera"
                    readonly
                    :rules="rules.pic"
                  >
                  </v-text-field
                  ><input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                /></v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">สินค้า</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-select
              label="สินค้า"
              v-model="product"
              :items="items"
              item-text="productName"
              item-value="id"
              solo
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="#234baa" dark >บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      product: "",
      items: [],
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  created() {
    this.getAllProduct();
  },

  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/
          `
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
    },
    // async getAllProduct() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/products/findAllAdmin?brandId=` +
    //       this.brand +
    //       "&typeId=" +
    //       this.type +
    //       "&categoryId=" +
    //       this.category
    //     // 'http://localhost:8081/types/'
    //   );
    //   console.log("getAllproducts", response.data);
    //   this.items = response.data.data;
    //   for (let i in this.items) {
    //     this.items[i].count = parseInt(i) + 1;
    //     //console.log(this.list);
    //   }
    // },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("Managebanners");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(Decode.decode(localStorage.getItem("userSPM")));
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const data = {
          // title: this.title,
          // description: this.brandDescription,
          // activeFlag: this.activeFlag,
          // description: this.content,
          imgUrl: this.pic1,
          productId: this.product,
        };
        if (this.activeFlag == true) {
          data.activeFlag = "1";
        } else if (this.activeFlag == false) {
          data.activeFlag = "0";
        }
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/banners`,
          data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างแบนเนอร์สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("Managebanners");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างแบนเนอร์ไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>